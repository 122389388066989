import React, { Component } from 'react';
import { connect } from 'react-redux';

import Form from './Form';

import * as actions from '../actions/item';
import {
  createUpdate, createAddItemTaxAmount, createUpdateItemTaxAmount, createDeleteItemTaxAmount, createItemExtraParam
} from '../actions';
import { closePopup } from '../actions/popup';

import { getTaxOptions, getSupplierAccountOptions } from '../selectors/dropdowns';
import { getFullItem, getPurchaseOrder } from '../selectors';

import { BASE_ZINDEX } from '../popup-factory';
import { createFormSelect } from './Form';
import TaxSelect from './TaxSelect';
import { isAvalaraOrder } from '../helpers/order';
import AvalaraCategorySelect from './avalara/AvalaraCategorySelect';
import Status from '../constants/Status';

const FormTaxSelect = createFormSelect(TaxSelect);

class EditArtworkPOPopup extends Component {

  constructor(props) {
    super(props);

    this.state = { field_lock: 'unit_cost' };
  }

  render() {
    const {
      order, item,
      default_tax_id,
      purchase_order,
      suppliers,
      taxes,
      onUpdateItem,
      onAddTaxAmount,
      onUpdateTaxAmount,
      onDeleteTaxAmount,
      onCreateItemExtraParam,
      onClosePopup,
      index,
      useMarginCurrency,
      zip2tax = false
    } = this.props;

    const { field_lock } = this.state;

    const handleDeleteTaxAmount = tax_amount_id => e => {
      e.preventDefault();
      onDeleteTaxAmount(tax_amount_id);
    };

    const onChangeTax = tax_amount_id => tax_id => {
      onUpdateTaxAmount(tax_amount_id, tax_id);
    };

    const item_name = item.item_name !== '' ? item.item_name : 'Other Service';
    const submitted_status = this.props.statuses[Status.PURCHASE_ORDER_SUBMITTED];

    return (
      <div id="service-modal" className ="reveal large" style={{display: 'block', zIndex: BASE_ZINDEX + index, overflow: 'visible'}} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
        <div className ="row">
          <div className ="small-12 columns">
            <h3 id="modalTitle">Service: {item_name}</h3>
          </div>
        </div>
        <div className ="row popup-content">
          <Form className="small-12 columns popup-size-limit">
            <Form.TextInput label="Title" field="item_name" value={item_name} disabled={true} />
            <Form.Select label="Supplier" field="division_id" searchable={true} options={suppliers} value={purchase_order.supplier_id} required={true} onChange={onCreateItemExtraParam(item.item_id, 'division_id')} withMarginBottom />
            <Form.DateInput label="Date Due" field="date_shipdaterequired" value={purchase_order.date_shipdaterequired} required={true} onChange={onCreateItemExtraParam(item.item_id, 'date_shipdaterequired')}  />
            <Form.Textarea label="Instructions" field="item_description" value={item.item_description} onBlur={onUpdateItem(item.item_id, 'item_description', item.item_description, field_lock)} />
            <Form.TextInput label="Quantity" field="total_units" value={item.total_units} required={true} onBlur={onUpdateItem(item.item_id, 'total_units', item.total_units, field_lock)} />
            <Form.TextInput label="Unit Cost" field="unit_cost" value={item.unit_cost} required={true} onBlur={onUpdateItem(item.item_id, 'unit_cost', item.unit_cost, field_lock)} />
            <Form.TextInput label="Margin" field="total_margin" value={item.total_margin ?? 'N/A'} required={1 != item.hidden} onBlur={onUpdateItem(item.item_id, 'total_margin', item.total_margin, field_lock)} disabled={item.hidden == 1 || parseFloat(item.exchange_rate) !== 1}/>
            <Form.TextInput label="Retail Price" field="unit_price" value={item.unit_price} required={true} onBlur={onUpdateItem(item.item_id, 'unit_price', item.unit_price, field_lock)} disabled={item.hidden == 1}/>
            <Form.TextInput label="Total" field="total_total" value={item.total_total} disabled={true} />
            <Form.Checkbox label="Hidden" field="hidden" value={item.hidden} style={{height: '38px'}} onChange={onUpdateItem(item.item_id, 'hidden', item.hidden, field_lock)} />
            {(order.currency_id !== item.currency_id && useMarginCurrency) &&
              <div className="row field">
                <div className="small-12 medium-4 columns">
                  <strong>Currency Conversion</strong>
                </div>
                <div className="small-12 medium-8 columns">
                  1 {item.currency_id} = <input type="text" style={{ width: '5rem', display: 'inline-block' }} readOnly={true} value={item.exchange_rate} /> {order.currency_id} <button className="button tiny" onClick={() => onUpdateItem(item.item_id, 'exchange_rate', item.exchange_rate, field_lock)(item.exchange_rate)}>Update</button>
                </div>
              </div>
            }
            {!isAvalaraOrder(order) && item.hidden == 0 && <div className="row field">
              <div className="small-12 medium-4 columns">
                <strong>Taxes</strong>
              </div>
              {item.tax_amounts.map((t, idx) => [
                <FormTaxSelect
                  key={`taxes.${idx}.tax_id`} zip2tax={zip2tax} value={t.tax_id} displayLabel={false}
                  containerClassName={'small-12 medium-4 columns' + (idx !== 0 ? ' medium-offset-4' : '')}
                  field={`taxes.${idx}.tax_id`} required={true} onChange={onChangeTax(t.tax_amount_id)}
                />,
                <Form.TextInput
                  key={`taxes.${idx}.amount`} value={t.amount} displayLabel={false}
                  containerClassName="small-12 medium-3 columns" field={`taxes.${idx}.amount`} disabled={true}
                />,
                !zip2tax && <div
                  key={`taxes.${idx}.delete`} className="small-12 medium-1 columns"
                  onClick={handleDeleteTaxAmount(t.tax_amount_id)}
                ><a style={{fontSize: '1.5em'}}>&times;</a></div>
              ])}
            </div>}
            {isAvalaraOrder(order) && <div className="row field">
              <div className="small-12 medium-4 columns">
                <strong>Avalara Category</strong>
              </div>
              <div className="small-12 medium-8 columns">
                <AvalaraCategorySelect
                  value={item.avalara_category_id}
                  onChange={(value) => {
                    onUpdateItem(item.item_id, 'avalara_category_id', item.avalara_category_id)(value);
                  }}
                />
              </div>
            </div>}
            {!isAvalaraOrder(order) && item.hidden == 0 && (!zip2tax || item.tax_amounts.length === 0) ?
              <div className="small-12 medium-offset-4 medium-8 columns" style={{marginBottom: '30px'}}>
                <a onClick={e => {e.preventDefault(); onAddTaxAmount(item.item_id, default_tax_id);}}>+ add another tax</a>
              </div> : null}
          </Form>
        </div>
        <a className="button" style={{position: 'absolute', right: '1rem', top: '1rem'}} onClick={e => {e.preventDefault(); onClosePopup();}}>
          Done
        </a>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = getFullItem(state, ownProps);
  const order = state.entities.orders[item.order_id];
  const job = state.entities.projects[order.job_id];
  return {
    order, item,
    default_tax_id: order.tax_id,
    zip2tax: 1 == job.zip2tax,
    suppliers: getSupplierAccountOptions(state),
    purchase_order : getPurchaseOrder(state, ownProps),
    taxes: getTaxOptions(state),
    useMarginCurrency: +state.identity.use_margin_currency === 1,
    statuses: state.entities.statuses
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });
  return {
    onUpdateItem: (item_id, field, previous_value, field_lock = 'unit_cost') => value => {
      dispatch(updateItem(item_id, field, previous_value, value, { field_lock }));
    },
    onAddTaxAmount: (parent_id, tax_id) => {
      dispatch(createAddItemTaxAmount(parent_id, tax_id));
    },
    onUpdateTaxAmount: (tax_amount_id, tax_id) => {
      dispatch(createUpdateItemTaxAmount(tax_amount_id, tax_id));
    },
    onDeleteTaxAmount: (tax_amount_id) => {
      dispatch(createDeleteItemTaxAmount(tax_amount_id));
    },
    onCreateItemExtraParam: (item_id, field) => value => {
      dispatch(createItemExtraParam(item_id, field, value));
    },
    onClosePopup: () => {
      dispatch(closePopup());
    }
  };
};

const ConnectedEditArtworkPOPopup = connect(mapStateToProps, mapDispatchToProps)(EditArtworkPOPopup);
export default ConnectedEditArtworkPOPopup;
